import { Address, ApiCallOptions, Facility, MxtsApi, PagedResult } from "@maxxton/cms-mxts-api";

import { Facilities } from "./facility.enum";
import { FacilityContentType } from "./model/FacilityContentType.model";
import { MXTS as MXTS_CONSTANTS } from "../../../utils/constants";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { SelectOptionLazyLoadResponse } from "../../../components/generic-form/LazyLoadAutoComplete";
import { getAdminMxtsEnv } from "../../mxts";

export const fetchFacilities = async ({
    env,
    includeAddress,
    facilityIds,
    resortId,
    sort,
}: {
    env: ApiCallOptions;
    includeAddress?: boolean;
    facilityIds?: number[];
    resortId?: number[];
    sort?: string;
}): Promise<Facilities> => {
    let allFacilities: Facility[] = [];
    let allAddresses: Address[] = [];

    if (includeAddress) {
        const allFacilitiesAndAddresses = await MxtsApi.facilitiesWithAddress(env, { size: MXTS_CONSTANTS.PAGE_REQUEST_SIZE, facilityId: facilityIds, resortId, sort });
        allFacilities = allFacilitiesAndAddresses?.data?.content;
        allAddresses = allFacilitiesAndAddresses?.addresses;
    } else {
        allFacilities = (await MxtsApi.facilities(env, { size: MXTS_CONSTANTS.PAGE_REQUEST_SIZE, facilityId: facilityIds, resortId, sort }))?.content;
    }
    return { facilities: allFacilities, addresses: allAddresses };
};

export async function facilityOptions(page: number, searchQuery?: string, ids?: string[]): Promise<SelectOptionLazyLoadResponse<Facility, number>> {
    const env = await getAdminMxtsEnv();
    const facilities: PagedResult<Facility> = await MxtsApi.facilities(env, {
        size: MXTS_CONSTANTS.MAX_RESULTS,
        page,
        name: searchQuery,
        ids: ids?.map((id) => +id),
    });
    return {
        selectOptions: facilities.content?.map(
            (facility: Facility): SelectOption<number> => ({
                value: facility.facilityId,
                label: facility?.name || facility?.code,
            })
        ),
        pagedResult: facilities,
    };
}

export const contentTypeMapping = {
    [FacilityContentType.NAME]: "name",
    [FacilityContentType.DESCRIPTION]: "description",
    [FacilityContentType.SHORT_DESCRIPTION]: "shortDescription",
    [FacilityContentType.OPENING_TIMES]: "groupedOpeningTimes",
    [FacilityContentType.IMAGE]: "facilityImages",
};

export const getStyles = (descFontColor: string) => ({ color: descFontColor?.indexOf("rgba") > -1 ? descFontColor : undefined });
