import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { LocalizedContentBase, Typesearch } from "@maxxton/cms-api";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { PageWidgetSpec, Widget } from "../../";
import { accoKindMultiSelector, assetSpec, contentTypeMultiSelector, resortMultiSelector } from "../../../components/utils";
import { resourcesAutocomplete, unitsAutocomplete } from "../../../inputSpecs";

import { ContentType } from "../../../components/components.enum";
import { ImageSpec } from "../../../media";
import { MarkerDetails } from "./mapWidget.types";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { WidgetGroup } from "../../widget.enum";
import { accoKindOptions } from "../../mxts";
import { facilityOptions } from "../../dynamic/facility/facility.util";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderPageWidgets } from "../../widget";
import { resultsPanelSpec } from "../../../form-specs/models/resultsPanel";

interface StyleOptions {
    value: string | null;
    text: string;
}

export interface MapSpecs {
    markers: MarkerDetails[];
    defaultZoom?: number;
    mapKey: string;
    onMouseOver?: MarkerDetails[];
}

export interface WidgetOptions extends LocalizedTitleOptions {
    mapSpecs: MapSpecs;
    contentTypes?: NumberMultiSelectOption[];
    resortMultiSelector?: NumberMultiSelectOption[];
    accoKindMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    showFacilitiesMarker?: boolean;
    googleMapIcon?: ImageSpec;
    activeMarkerIcon?: ImageSpec;
    showMarkerForSearchResults?: boolean;
    choosableOnInternet?: boolean;
    choosablePriceMarker?: boolean;
    choosableNightPrice?: boolean;
    choosableTotalPrice?: boolean;
    completeMapClickable?: boolean;
    dynamicMarkerDefaultZoom?: boolean;
    resultsPanelModelType?: string;
    showMarkerTooltip?: boolean;
    enableCustomerMarkerIconColor?: boolean;
    iconcolorBeforeHover?: string;
    iconcolorAfterHover?: string;
    unitBookUri?: string;
    mapTypeId?: string;
    regionId?: number;
    showSearchButton?: boolean;
    poiOption?: boolean;
    width?: number;
    height?: number;
    useGroundOverlay?: boolean;
    useStreetView?: boolean;
    assetType?: string;
    removeMapsContent?: string;
    localized?: Localized[];
    styleIds?: StyleOptions[];
    enableUserInteraction?: boolean;
    enableBlur: boolean;
    OverlayImage?: ImageSpec;
    localizedOverlayText?: localizedOverlayText[];
    useImageMap?: boolean;
    enableClustering?: boolean;
    enableMinClusterSize?: boolean;
    minClusterSize?: number;
    accoKindMapTypeId?: string;
    displayDiffMap?: boolean;
    priceLabelBackground?: string;
    selectedAccoKinds?: NumberMultiSelectOption[];
    clusterIconImageUrl1x?: ImageSpec;
    clusterIconImageUrl2x?: ImageSpec;
    clusterIconImageUrl3x?: ImageSpec;
    facilityIds?: NumberMultiSelectOption[];
    useForActivityPlanner?: boolean;
}
export interface Localized extends LocalizedContentBase {
    imageOverlay: ImageSpec;
    latBottomLeft?: number;
    latTopRight?: number;
    longBottomLeft?: number;
    longTopRight?: number;
}

export interface localizedOverlayText extends LocalizedContentBase {
    overlayText?: string;
}

const TARGETS = ["map"];

const mapTypeOptions = [
    {
        value: "satellite",
        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "satelliteView"),
    },
    {
        value: "roadmap",
        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "roadMapView"),
    },
    {
        value: "terrain",
        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "terrainView"),
    },
    {
        value: "hybrid",
        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "hybridView"),
    },
];

const mapWidgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "map-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetMap, "mapOption"),
    pluralName: getI18nLocaleObject(namespaceList.widgetMap, "mapOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "mapTypeId",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "defaultMapView"),
                                type: "select",
                                default: "roadmap",
                                optionList: mapTypeOptions,
                            },
                            {
                                variable: "displayDiffMap",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "displayDiffMap"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "selectedAccoKinds",
                                type: "multiselect",
                                async optionList(): Promise<NumberMultiSelectOption[]> {
                                    return accoKindOptions(MxtsApi);
                                },
                                visible: (options: WidgetOptions) => options.displayDiffMap,
                            },
                            {
                                variable: "accoKindMapTypeId",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "selectAccoMapView"),
                                type: "select",
                                default: "satellite",
                                optionList: mapTypeOptions,
                                visible: (options: WidgetOptions) => options.displayDiffMap && options.selectedAccoKinds?.length,
                            },
                            {
                                variable: "poiOption",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "poiOption"),
                                default: false,
                                type: "checkbox",
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "static"),
                    properties: [
                        [
                            {
                                variable: "mapSpecs",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "map"),
                                type: "map",
                            },
                            {
                                variable: "dynamicMarkerDefaultZoom",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "dynamicMarkerDefaultZoom"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                variable: "width",
                                label: getI18nLocaleObject(namespaceList.widgetIframe, "width"),
                                type: "number",
                            },
                            {
                                variable: "height",
                                label: getI18nLocaleObject(namespaceList.widgetIframe, "height"),
                                type: "number",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "dynamic"),
                    properties: [
                        [
                            contentTypeMultiSelector("contentTypes", true, true),
                            resortMultiSelector("resortMultiSelector", true),
                            accoKindMultiSelector("accoKindMultiSelector", true),
                            resourcesAutocomplete("resourceId"),
                            unitsAutocomplete("unitId"),
                            {
                                variable: "facilityIds",
                                label: getI18nLocaleObject(namespaceList.admin, "facilityIds"),
                                type: "lazyLoadMultiSelect",
                                lazyLoadOptions: (page: number, searchQuery: string, ids: string[] | undefined) => facilityOptions(page, searchQuery, ids),
                                visible: (options: WidgetOptions) => options?.contentTypes?.find((type: NumberMultiSelectOption) => type.value === ContentType.FACILITY),
                                placeholder: getI18nLocaleObject(namespaceList.admin, "facilityIds"),
                            },
                            {
                                variable: "useForActivityPlanner",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "useForActivityPlanner"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options?.contentTypes?.find((type: NumberMultiSelectOption) => type.value === ContentType.FACILITY),
                            },
                            {
                                variable: "showFacilitiesMarker",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "showFacilitiesMarker"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => {
                                    if (Array.isArray(options.contentTypes)) {
                                        let selected = false;
                                        options.contentTypes.forEach((contentType: any) => {
                                            if (contentType.value === ContentType.RESORT) {
                                                selected = true;
                                            }
                                        });
                                        return selected;
                                    }
                                    return false;
                                },
                            },
                            {
                                variable: "showMarkerForSearchResults",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "showMarkerForSearchResults"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "showSearchButton",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "showSearchButton"),
                                default: false,
                                visible: (options: WidgetOptions) => !!options.showMarkerForSearchResults,
                            },
                            {
                                variable: "enableClustering",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "showMarkersWithClustering"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "descriptionMinClusterSize"),
                                visible: (options: WidgetOptions) => options.enableClustering,
                            },
                            {
                                variable: "enableMinClusterSize",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "enableMinClusterSize"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.enableClustering,
                            },
                            {
                                variable: "clusterIconImageUrl1x",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "clusterIconImageUrl1x"),
                                type: "image",
                                visible: (options: WidgetOptions) => options.enableClustering,
                            },
                            {
                                variable: "clusterIconImageUrl2x",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "clusterIconImageUrl2x"),
                                type: "image",
                                visible: (options: WidgetOptions) => options.enableClustering,
                            },
                            {
                                variable: "clusterIconImageUrl3x",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "clusterIconImageUrl3x"),
                                type: "image",
                                visible: (options: WidgetOptions) => options.enableClustering,
                            },
                            {
                                variable: "minClusterSize",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "minClusterSize"),
                                type: "number",
                                default: 2,
                                visible: (options: WidgetOptions) => options.enableClustering && options.enableMinClusterSize,
                            },
                            {
                                variable: "choosableOnInternet",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "choosableOnInternet"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "choosablePriceMarker",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "choosablePriceMarker"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "choosableNightPrice",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "choosableNightPrice"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.choosablePriceMarker,
                            },
                            {
                                variable: "choosableTotalPrice",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "showTotalPrice"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.choosablePriceMarker,
                            },
                            {
                                variable: "priceLabelBackground",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "bg"),
                                type: "dual-color",
                                default: "theme-ctaColor",
                                visible: (options: WidgetOptions) => !!options.choosablePriceMarker,
                            },
                            {
                                variable: "googleMapIcon",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "customMarkerImage"),
                                type: "image",
                            },
                            {
                                variable: "activeMarkerIcon",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "activeMarkerImage"),
                                type: "image",
                            },
                            {
                                variable: "unitBookUri",
                                label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "unitBookUri"),
                                type: "text",
                            },
                            {
                                variable: "resultsPanelModelType",
                                label: getI18nLocaleObject(namespaceList.admin, "typesearchModel"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                                visible: (options: WidgetOptions) => {
                                    if (options.contentTypes && options.contentTypes.length > 0) {
                                        let selected = false;
                                        options.contentTypes.forEach((contentType: any) => {
                                            // value for resort content type is 0, so to avoid comparing falsy value explicitly checking undefined
                                            if (contentType.value !== undefined) {
                                                selected = true;
                                            }
                                        });
                                        return selected;
                                    } else if (options.showMarkerForSearchResults) {
                                        return true;
                                    }
                                    return false;
                                },
                            },
                            {
                                variable: "completeMapClickable",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "completeMapClickable"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "showMarkerTooltip",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "showMarkerTooltip"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "enableCustomerMarkerIconColor",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "enableCustomerMarkerIconColor"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "iconcolorBeforeHover",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "iconcolorBeforeHover"),
                                type: "text",
                                visible: (options: any) => options.enableCustomerMarkerIconColor,
                            },
                            {
                                variable: "iconcolorAfterHover",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "iconcolorAfterHover"),
                                type: "text",
                                visible: (options: any) => options.enableCustomerMarkerIconColor,
                            },
                            {
                                variable: "regionId",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "regionId"),
                                type: "text",
                                visible: (options: any) => options.enableCustomerMarkerIconColor,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "options"),
                    properties: [
                        [
                            {
                                variable: "enableUserInteraction",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "enableUserInteraction"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "OverlayImage",
                                type: "image",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "image"),
                                visible: (options: any) => options.enableUserInteraction,
                            },
                            {
                                variable: "useImageMap",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "useImageMap"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "enableBlur",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "enableBlur"),
                                type: "checkbox",
                                default: false,
                                visible: (options: any) => options.enableUserInteraction,
                            },
                            localized({
                                variable: "localizedOverlayText",
                                visible: (options: any) => options.enableUserInteraction,
                                tabContent: [
                                    {
                                        variable: "overlayText",
                                        type: "text",
                                        label: getI18nLocaleObject(namespaceList.widgetMap, "localizedOverlayText"),
                                    },
                                ],
                            }),
                            {
                                variable: "useGroundOverlay",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "useGroundImageOverlay"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "useStreetView",
                                label: getI18nLocaleObject(namespaceList.widgetMap, "useStreetView"),
                                default: false,
                                type: "checkbox",
                            },
                            { ...assetSpec("assetType", "useGroundOverlay", "imageOverlay", undefined, true) },
                        ],
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.widgetMap, "useGroundOverlayDescription"),
                                type: "paragraph",
                                visible: (options: any) => options.useGroundOverlay,
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "latBottomLeft",
                                        label: getI18nLocaleObject(namespaceList.widgetMap, "latBottomLeft"),
                                        type: "number",
                                    },
                                    {
                                        variable: "longBottomLeft",
                                        label: getI18nLocaleObject(namespaceList.widgetMap, "longBottomLeft"),
                                        type: "number",
                                    },
                                    {
                                        variable: "latTopRight",
                                        label: getI18nLocaleObject(namespaceList.widgetMap, "latTopRight"),
                                        type: "number",
                                    },
                                    {
                                        variable: "longTopRight",
                                        label: getI18nLocaleObject(namespaceList.widgetMap, "longTopRight"),
                                        type: "number",
                                    },
                                ],
                                visible: (options: any) => options.useGroundOverlay,
                            }),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const mapWidget: PageWidgetSpec<WidgetOptions> = {
    id: "map",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetMap, "map"),
    description: getI18nLocaleObject(namespaceList.widgetMap, "map"),
    optionsForm: mapWidgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        mapSpecs: { markers: [], mapKey: "", onMouseOver: [] },
        showFacilitiesMarker: true,
        choosablePriceMarker: false,
        resultsPanelModelType: "",
        completeMapClickable: false,
        showMarkerTooltip: false,
        enableCustomerMarkerIconColor: false,
        iconcolorBeforeHover: "",
        iconcolorAfterHover: "",
        unitBookUri: "",
        mapTypeId: "roadmap",
        poiOption: false,
        useGroundOverlay: false,
        useStreetView: false,
        assetType: "imageOverlay",
        styleIds: [],
        dynamicMarkerDefaultZoom: false,
        enableUserInteraction: false,
        enableBlur: false,
        useImageMap: false,
        enableClustering: false,
        displayDiffMap: false,
        selectedAccoKinds: [],
        accoKindMapTypeId: "satellite",
        priceLabelBackground: "theme-ctaColor",
    }),

    async render(widget: Widget<WidgetOptions>, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const { resultsPanelModelType } = widget.options;
        const typeLayout: Typesearch | null = resultsPanelModelType ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelModelType }) : null;
        const children = typeLayout ? await renderPageWidgets(typeLayout.root, context) : undefined;
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds || []);

        return (
            <LazyLoadWhenVisible
                // eslint-disable-next-line react/jsx-no-bind
                onVisible={async () => {
                    const MapContainer = loadable(() => loadableRetry(() => import("./MapContainer")), {
                        resolveComponent: ({ MapContainer }) => MapContainer,
                    });
                    return (
                        <MapContainer
                            options={widget.options}
                            locale={context.currentLocale.code}
                            dynamicContainerOptions={dynamicContainerOptions}
                            context={context}
                            children={children}
                            className={styleClasses}
                        />
                    );
                }}
            ></LazyLoadWhenVisible>
        );
    },
};

const LazyLoadWhenVisible = (props: { onVisible: () => Promise<React.JSX.Element> }) => {
    const { onVisible } = props;
    const [visibleContents, setVisibleContents] = React.useState<React.JSX.Element | undefined>();
    const [isVisible, setIsVisible] = React.useState(false);
    const [isIntersectionTriggeredOnce, setIntersectionTriggeredOnce] = React.useState(false);
    const elementRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        const currentElement = elementRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (!isIntersectionTriggeredOnce) {
                    // For some reason the IntersectionObserver doesn't properly see the revealing sometimes. Triggering a re-render like this works around that issue.
                    setIntersectionTriggeredOnce(true);
                }
                if (entry.isIntersecting) {
                    observer.disconnect();
                    setIsVisible(true);
                    if (currentElement) {
                        observer.unobserve(currentElement);
                    }
                }
            },
            {
                threshold: 0.1, // Adjust the threshold as needed (0 means any part is visible, 1 means fully visible)
            }
        );

        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [isIntersectionTriggeredOnce]);

    React.useEffect(() => {
        if (isVisible) {
            onVisible?.().then((loadedContents) => setVisibleContents(loadedContents));
        }
    }, [onVisible, isVisible]);

    return <div ref={elementRef}>{visibleContents || undefined}</div>;
};
